import { useI18n } from "../../src/ui/hooks/useI18n";
import { forwardRef, useState } from "react";
import { useCountryConfig } from "../../src/ui/hooks/useCountryConfig";
import ReactInputMask from "react-input-mask-format";
import { SecurityNumberInputProps } from "../../src/appConfig";
import { LightStyledInput } from "../../src/ui/components/mui/LightStyledInput";

export function SecurityNumberInput(props: SecurityNumberInputProps) {
  const { isReadonly, defaultValue } = props;
  const { t } = useI18n();
  const { securityNumber } = useCountryConfig();
  const [invalidNumber, setInvalidNumber] = useState<boolean>(false);

  return (
    <>
      <LightStyledInput
        fullWidth
        label={t("common.user_infos.social_security_number")}
        placeholder="ex : 121282-88882"
        inputComponent={!isReadonly ? (SocialSecurityMaskInput as any) : undefined}
        readOnly={isReadonly}
        name="externalId"
        onChange={(_e) => setInvalidNumber(false)}
        defaultValue={defaultValue}
        onBlur={(e) => setInvalidNumber(!securityNumber.validator((e.target.value)))}
        error={invalidNumber}
        errorMessage={invalidNumber ? t("common.errors.securityNumber") : undefined}
      />
    </>
  );
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  value: string;
}

const SocialSecurityMaskInput = forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  return (
    <ReactInputMask
      {...props}
      mask="999999-99999"
      onChange={props.onChange}
      value={props.value}
      ref={ref as any}
    />
  );
});
